import React, { useMemo, useContext, createContext, useCallback } from 'react';
import { PaginatedVisitors, VisitorCreateOrEditPayload } from 'clients/VisitorClient';
import { visitorClient } from 'clients';

import useReducerWithMiddleware from '../useReducerWithMiddleware';
import unauthorized from '../unauthorized';
import visitorsReducer, { VisitorsState, initialVisitorsState } from './reducer';
import {
    ADD_VISITOR_FAILED,
    ADD_VISITOR_REQUESTED,
    ADD_VISITOR_SUCCESS,
    DELETE_VISITOR_FAILED,
    DELETE_VISITOR_REQUESTED,
    DELETE_VISITOR_SUCCESS,
    EDIT_VISITOR_FAILED,
    EDIT_VISITOR_REQUESTED,
    EDIT_VISITOR_SUCCESS,
    VISITORS_FAILED,
    VISITORS_REQUESTED,
    VISITORS_SUCCESS,
} from './constants';

interface VisitorsActions {
    getVisitors: (spotPk: number, currentFilters: VisitorFilters) => void;
    addVisitor: (visitor: VisitorCreateOrEditPayload, spotPk: number, currentFilters: VisitorFilters) => void;
    editVisitor: (
        visitor: VisitorCreateOrEditPayload,
        visitorPk: number,
        spotPk: number,
        currentFilters: VisitorFilters
    ) => void;
    deleteVisitor: (visitorPk: number, spotPk: number, currentFilters: VisitorFilters) => void;
}

type VisitorFilters = {
    page: number;
    expiring: boolean;
    search: string;
};

interface VisitorsContextType extends VisitorsState, VisitorsActions {}

export const VisitorsContext = createContext<VisitorsContextType | null>(null);

export const VisitorsProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
    const [state, dispatch] = useReducerWithMiddleware(
        visitorsReducer,
        { ...initialVisitorsState },
        [],
        [unauthorized]
    );

    const getVisitors = useCallback(
        async (spotPk: number, currentFilters: VisitorFilters) => {
            const { page, expiring, search } = currentFilters;
            dispatch({ type: VISITORS_REQUESTED });

            try {
                const visitors: PaginatedVisitors = await visitorClient.getVisitors(spotPk, page, expiring, search);
                dispatch({ type: VISITORS_SUCCESS, payload: { visitors } });
            } catch (error) {
                dispatch({
                    type: VISITORS_FAILED,
                    payload: { error, message: error?.toString() || 'Fetching visitors failed.' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const addVisitor = useCallback(
        async (visitor: VisitorCreateOrEditPayload, spotPk: number, currentFilters: VisitorFilters) => {
            const { page, expiring, search } = currentFilters;
            dispatch({ type: ADD_VISITOR_REQUESTED });
            try {
                const newVisitor = await visitorClient.addVisitor(visitor);
                const newVisitorCar =
                    newVisitor.car_details.plate +
                    (newVisitor.car_details.state ? ` - ${newVisitor.car_details.state}` : '');
                const visitors: PaginatedVisitors = await visitorClient.getVisitors(spotPk, page, expiring, search);

                dispatch({
                    type: ADD_VISITOR_SUCCESS,
                    payload: {
                        message: 'Added visitor with plate: ' + newVisitorCar,
                        visitors,
                    },
                });
            } catch (error) {
                dispatch({
                    type: ADD_VISITOR_FAILED,
                    payload: { message: error?.toString() || 'Could not add visitor' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const editVisitor = useCallback(
        async (
            visitor: VisitorCreateOrEditPayload,
            visitorPk: number,
            spotPk: number,
            currentFilters: VisitorFilters
        ) => {
            const { page, expiring, search } = currentFilters;
            dispatch({ type: EDIT_VISITOR_REQUESTED });
            try {
                const newVisitor = await visitorClient.editVisitor(visitor, visitorPk);
                const newVisitorCar =
                    newVisitor.car_details.plate +
                    (newVisitor.car_details.state ? ` - ${newVisitor.car_details.state}` : '');
                const visitors: PaginatedVisitors = await visitorClient.getVisitors(spotPk, page, expiring, search);

                dispatch({
                    type: EDIT_VISITOR_SUCCESS,
                    payload: {
                        message: 'Edited visitor with plate: ' + newVisitorCar,
                        visitors,
                    },
                });
            } catch (error) {
                dispatch({
                    type: EDIT_VISITOR_FAILED,
                    payload: { message: error?.toString() || 'Could not edit visitor' },
                });
            }
        },
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const deleteVisitor = useCallback(async (visitorPk: number, spotPk: number, currentFilters: VisitorFilters) => {
        const { page, expiring, search } = currentFilters;

        dispatch({ type: DELETE_VISITOR_REQUESTED });
        try {
            await visitorClient.deleteVisitor(visitorPk);
            const visitors: PaginatedVisitors = await visitorClient.getVisitors(spotPk, page, expiring, search);
            dispatch({
                type: DELETE_VISITOR_SUCCESS,
                payload: {
                    message: 'Deleted visitor successfully',
                    visitors,
                },
            });
        } catch (error) {
            dispatch({
                type: DELETE_VISITOR_FAILED,
                payload: { message: error?.toString() || 'Could not delete visitor' },
            });
        }
        // until this is fixed: https://github.com/reactjs/react.dev/issues/1889,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo<VisitorsContextType>(() => {
        return {
            ...state,
            getVisitors,
            addVisitor,
            editVisitor,
            deleteVisitor,
        };
    }, [state, getVisitors, addVisitor, editVisitor, deleteVisitor]);
    return <VisitorsContext.Provider value={value}>{children}</VisitorsContext.Provider>;
};

export const useVisitors = () => {
    const context = useContext(VisitorsContext);
    if (!context) {
        throw new Error('Error: useVisitors should be wrapped by VisitorsProvider.');
    }
    return context;
};
