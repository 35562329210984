import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AppState } from 'reducers/reducers';
import { selectedSpotInfo } from 'selectors';
import { CarTrackingStatus } from 'types/CarTracking';
import usePaginatedFiltersWithSearch from 'hooks/usePaginatedFiltersWithSearch';
import { isValidDateForCarTrackingTable } from 'utils/helpers';
import Loader from 'components/Loader/Loader';
import DriverLog from 'components/DriverLog/DriverLog';
import VisibilityCameras from 'components/VisibilityCameras/VisibilityCameras';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';
import HeroStatsVisibility from 'components/HeroStatsVisibility/HeroStatsVisibility';
import './VisibilityPageContainer.css';

type VisibilityPageContainerProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ENABLE_HERO_STATS_SPOT_PKS = [
    1030, // Washington Hill
];

function VisibilityPageContainer({ spot, loadingSpots, push, profile }: VisibilityPageContainerProps): JSX.Element {
    const { filters, urlUpdater } = usePaginatedFiltersWithSearch<{
        date: string;
        status: CarTrackingStatus;
    }>({
        filterValidators: {
            date(current) {
                return current && isValidDateForCarTrackingTable(current) ? current : new Date().toLocaleDateString();
            },
            status(current) {
                return current && Object.values(CarTrackingStatus).includes(current as CarTrackingStatus)
                    ? current
                    : '';
            },
        },
        push,
    });

    if (!spot || loadingSpots) return <Loader />;

    return (
        <div className="visibilityPage">
            <VisibilityCameras spotUuid={spot.uuid} />
            {(ENABLE_HERO_STATS_SPOT_PKS.includes(spot.pk) || profile.superuser) && (
                <HeroStatsVisibility spotUuid={spot.uuid} />
            )}
            <AuthorizedView allowed={[Roles.Superuser]}>
                <DriverLog spotUuid={spot.uuid} filters={filters} urlUpdater={urlUpdater} timezone={spot.timezone} />
            </AuthorizedView>
        </div>
    );
}

function mapStateToProps(state: AppState) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        spot: selectedSpotInfo(state)!,
        loadingSpots: state.spots.orgSpotsLoading,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profile: state.auth.profile!,
    };
}

const mapDispatchToProps = {
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityPageContainer);
