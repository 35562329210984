import React, { useState } from 'react';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import AGDatePicker from 'components/AGDatePicker/AGDatePicker';
import AGDropdown from 'components/AGDropdown/AGDropdown';
import styles from './SingleDateSelector.module.css';
import { getFormattedDate } from 'utils/helpers';

type SingleDateSelectorProps = {
    date?: Date | undefined;
    onSave: (date: Date | undefined) => void;
    dropdownWidth?: string;
    isInModal?: boolean;
    placeHolder: string;
    latestAvailableDate?: Date;
    allowDeSelect?: boolean;
    noIcon?: boolean;
    disabled?: boolean;
};

export default function SingleDateSelector({ allowDeSelect = true, ...props }: SingleDateSelectorProps) {
    const [openDropdown, toggleOpenDropdown] = useState(false);
    const [date, setDate] = useState<Date | undefined>(props.date);

    function handleSave(date: Date | undefined) {
        setDate(date);
        props.onSave(date);
        toggleOpenDropdown(false);
    }

    return (
        <AGDropdown
            text={`${date ? getFormattedDate(date) : props.placeHolder}`}
            icon={props.noIcon ? undefined : <Calendar className={styles.calendarIcon} />}
            open={openDropdown}
            toggleOpen={toggleOpenDropdown}
            width={props.dropdownWidth || '290px'}
            isInModal={props.isInModal}
            disabled={props.disabled || false}
        >
            <div className={styles.dateSelector}>
                <AGDatePicker
                    setStartDate={handleSave}
                    startDate={date}
                    latestAvailableDate={props.latestAvailableDate}
                    allowDeSelect={allowDeSelect}
                />
            </div>
        </AGDropdown>
    );
}
