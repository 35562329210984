import React, { useState } from 'react';
import { Button, Modal, Form, Header, Icon, Dropdown } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import useForm from 'hooks/useForm';
import { getDropdownNumberOptions, getDropdownOptionsFromArray } from 'utils/helpers';
import { cleanData, formatWithTimeZone } from './utils';
import styles from './PromoCodeForm.module.css';

export enum PromoCodeType {
    Dollar = 'dollar',
    Percentage = 'percentage',
    Time = 'time',
}

export interface PromoCodeFormInputs {
    code: string;
    notes: string;
    value: string | null;
    expiration: Date | null;
    number_of_uses: string | null;
    percentage_discount: string | null;
    minutes_to_discount: number | null;
    hours_to_discount: number | null;
    maximum_number_of_simultaneous_rentals: number | null;
}

const initialFormState: PromoCodeFormInputs = {
    code: '',
    notes: '',
    value: null,
    expiration: null,
    number_of_uses: null,
    percentage_discount: null,
    minutes_to_discount: 0,
    hours_to_discount: 0,
    maximum_number_of_simultaneous_rentals: null,
};

type PromoCodeFormProps = {
    isShowing: boolean;
    toggle: () => void;
    spotTimezone: string;
    addPromoCode: (inputs: PromoCodeFormInputs) => void;
};

const PromoCodeForm = (props: PromoCodeFormProps) => {
    const { handleInput, inputs, handleValueChange, handleDropdown } = useForm(initialFormState);
    const [promoCodeType, setPromoCodeType] = useState<PromoCodeType>(PromoCodeType.Dollar);
    const { isShowing, toggle, addPromoCode, spotTimezone } = props;

    function addPromoCodeAndClose() {
        if (inputs.expiration && !!spotTimezone)
            inputs.expiration = formatWithTimeZone(inputs.expiration, spotTimezone).toDate();
        const cleanInputs = cleanData(inputs, promoCodeType);
        addPromoCode(cleanInputs);
        toggle();
    }

    const formattedDate = inputs.expiration ? new Date(inputs.expiration) : null;

    return (
        <Modal as={Form} open={isShowing} onClose={toggle} onSubmit={addPromoCodeAndClose}>
            <Header content={'New discount code'} as="h2" />
            <Modal.Content>
                <Form.Input
                    name="code"
                    label="Discount code"
                    value={inputs.code}
                    onChange={handleInput}
                    type="text"
                    placeholder="COFFEE123"
                    required
                />

                <div style={{ marginBottom: '1em' }}>
                    <label className={styles.customLabel}>Type</label>
                    <br />
                    <Button.Group>
                        <Button
                            type="button"
                            onClick={() => setPromoCodeType(PromoCodeType.Dollar)}
                            active={promoCodeType === PromoCodeType.Dollar}
                        >
                            Dollar amount
                        </Button>
                        <Button.Or />
                        <Button
                            type="button"
                            onClick={() => setPromoCodeType(PromoCodeType.Percentage)}
                            active={promoCodeType === PromoCodeType.Percentage}
                        >
                            Percentage amount
                        </Button>
                        <Button.Or />
                        <Button
                            type="button"
                            onClick={() => setPromoCodeType(PromoCodeType.Time)}
                            active={promoCodeType === PromoCodeType.Time}
                        >
                            Time amount
                        </Button>
                    </Button.Group>
                </div>

                {promoCodeType === PromoCodeType.Dollar && (
                    <div style={{ flexDirection: 'row' }}>
                        <Form.Input
                            name="value"
                            iconPosition="left"
                            label="Dollar amount"
                            value={inputs.value}
                            onChange={handleInput}
                            type="number"
                            placeholder="5"
                            required
                        >
                            <Icon name="dollar" />
                            <input />
                        </Form.Input>
                    </div>
                )}
                {promoCodeType === PromoCodeType.Percentage && (
                    <div style={{ flexDirection: 'row' }}>
                        <Form.Input
                            name="percentage_discount"
                            iconPosition="left"
                            label="Percentage off parking rate"
                            value={inputs.percentage_discount}
                            onChange={handleInput}
                            type="number"
                            placeholder="5"
                            required
                        >
                            <Icon name="percent" />
                            <input />
                        </Form.Input>
                    </div>
                )}
                {promoCodeType === PromoCodeType.Time && (
                    <p style={{ fontSize: '16px' }}>
                        <span>Free for </span>
                        <Dropdown
                            search
                            inline
                            autoFocus={true}
                            onChange={handleDropdown}
                            name="hours_to_discount"
                            value={inputs.hours_to_discount || 0}
                            options={getDropdownNumberOptions(13)}
                        />
                        <span> hours and </span>
                        <Dropdown
                            search
                            inline
                            onChange={handleDropdown}
                            name="minutes_to_discount"
                            value={inputs.minutes_to_discount || 0}
                            options={getDropdownOptionsFromArray([0, 15, 30, 45])}
                        />
                        <span> minutes</span>
                    </p>
                )}
                <br />
                <Form.Input
                    name="notes"
                    label="Description (for your reference only)"
                    value={inputs.notes}
                    onChange={handleInput}
                    type="text"
                    placeholder="King Coffee discount"
                />
                <div className={styles.inputGroupAddonContainer}>
                    <div>
                        <label className={styles.customLabel}>Expiration</label>
                        <br />
                        <Form.Group>
                            <DatePicker
                                id="expirationpromo"
                                name="expirationpromo"
                                selected={formattedDate}
                                onChange={(d: Date | null) => handleValueChange('expiration', d)}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                showTimeSelect
                                placeholderText="never"
                                className={styles.expirationpromo}
                            />
                            <Button
                                color={'red'}
                                size="mini"
                                onClick={() => handleValueChange('expiration', null)}
                                icon="trash"
                                float="right"
                                type="button"
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <label className={styles.customLabel}>Number of uses</label>
                        <br />
                        <Form.Group>
                            <Form.Input
                                id="number_of_uses"
                                value={inputs.number_of_uses || ''}
                                name="number_of_uses"
                                onChange={handleInput}
                                type="number"
                                placeholder="infinite"
                                min="0"
                                className={styles.numberofusespromo}
                            />
                            <Button
                                color={'red'}
                                size="mini"
                                onClick={() => handleValueChange('number_of_uses', '')}
                                icon="trash"
                                float="right"
                                type="button"
                            />
                        </Form.Group>
                    </div>

                    <div>
                        <label className={styles.customLabel}>Maximum number of active rentals</label>
                        <br />
                        <Form.Group>
                            <Form.Input
                                id="maximum_number_of_simultaneous_rentals"
                                value={inputs.maximum_number_of_simultaneous_rentals || ''}
                                name="maximum_number_of_simultaneous_rentals"
                                onChange={handleInput}
                                type="number"
                                placeholder="infinite"
                                min="1"
                                className={styles.numberofusespromo}
                            />
                            <Button
                                color={'red'}
                                size="mini"
                                onClick={() => handleValueChange('maximum_number_of_simultaneous_rentals', null)}
                                icon="trash"
                                float="right"
                                type="button"
                            />
                        </Form.Group>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" color="green" icon="save" content="Save" />
            </Modal.Actions>
        </Modal>
    );
};

export default PromoCodeForm;
