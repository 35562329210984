import React, { useCallback, useEffect, useState } from 'react';
import { trackingClient } from 'clients';
import { HTTPError } from 'clients/HTTPClient';
import { HeroStats } from 'types/CarTracking';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import AGMessage from 'components/AGMessage/AGMessage';
import Loader from 'components/Loader/Loader';
import CarIcon from 'components/Icons/CarIcon';
import OccupancyBars from './OccupancyBars';
import styles from './HeroStatsVisibility.module.css';

type HeroStatsVisibilityProps = {
    spotUuid: string;
};

function HeroStatsVisibility({ spotUuid }: HeroStatsVisibilityProps): JSX.Element {
    const [heroStats, setHeroStats] = useState<HeroStats>();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const getHeroStats = useCallback(
        async function _getHeroStats() {
            setLoading(true);
            try {
                const response = await trackingClient.getHeroStats(spotUuid);
                setHeroStats(response);
            } catch (e) {
                if (HTTPError.isHTTPError(e)) {
                    setErrorMessage(e.message);
                } else {
                    setErrorMessage(e?.toString() || 'Something went wrong');
                }
            } finally {
                setLoading(false);
            }
        },
        [spotUuid]
    );

    useEffect(() => {
        getHeroStats();
    }, [getHeroStats]);

    return (
        <div className={styles.container}>
            <div className="visibility-section">
                <h3>Occupancy</h3>
                {errorMessage && <AGMessage color="error" title={errorMessage} />}
                {loading && <Loader />}
                <div>{heroStats && <OccupancyBars heroStats={heroStats} />}</div>
            </div>
            <div className="visibility-section">
                {loading && <Loader />}

                {heroStats && (
                    <div className={styles.itemsContainer}>
                        <div className={styles.item}>
                            <p>
                                <EyeIcon />
                                {heroStats.vehicles_seen}
                            </p>
                            <span>Vehicles Seen</span>
                        </div>
                        <div className={styles.item}>
                            <p>
                                <CarIcon />
                                {heroStats.transient_rentals}
                            </p>
                            <span>Transient Rentals</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeroStatsVisibility;
