import moment from 'moment';
import { PromoCodeFormInputs, PromoCodeType } from './PromoCodeForm';

const formatMinute = (value: number) => {
    // If value is single digit concat zero to beginning
    // Otherwise return original value
    // Ex: value=7 return "07" or value=15 return "15"
    return value < 10 ? `0${value}` : String(value);
};

export const formatWithTimeZone = (expirationDate: Date, spotTimezone: string) => {
    const d = expirationDate;
    // Recreate the date with the correct timezone
    // date.getMonth() is zero based
    const str_date = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${formatMinute(
        d.getMinutes()
    )}`;
    const date = moment.tz(str_date, 'YYYY-MM-DD HH:mm', spotTimezone);
    return moment.utc(date);
};

export const cleanData = (inputs: PromoCodeFormInputs, promoCodeType: PromoCodeType): PromoCodeFormInputs => {
    switch (promoCodeType) {
        case PromoCodeType.Dollar:
            inputs.percentage_discount = null;
            inputs.minutes_to_discount = null;
            break;
        case PromoCodeType.Percentage:
            inputs.value = null;
            inputs.minutes_to_discount = null;
            break;
        case PromoCodeType.Time:
            inputs.value = null;
            inputs.percentage_discount = null;
            inputs.minutes_to_discount = (inputs.hours_to_discount || 0) * 60 + (inputs.minutes_to_discount || 0);
            break;
    }
    delete inputs.hours_to_discount;
    return inputs;
};
