import { VisitorCode } from 'types/VisitorCode';

export type FormattedVisitorCode = {
    text: string;
    value: string;
    key: string;
};

export const selectVisitorCodesListWithNames = (visitorCodes: VisitorCode[]): FormattedVisitorCode[] => {
    return visitorCodes.map((visitorCode: VisitorCode) => {
        const { code, name, pk } = visitorCode;
        const days = visitorCode.days_valid ? `${visitorCode.days_valid} days` : '';
        const hours = visitorCode.hours_valid ? `${visitorCode.hours_valid} hours` : '';
        const temporary_validation = days || hours ? `${days} ${hours}` : '';
        return { key: code, text: `${name} ${temporary_validation} (${code})`, value: pk.toString() };
    });
};
