import React, { useEffect, useState } from 'react';
import { View } from 'react-big-calendar';
import useForm from 'hooks/useForm';
import { timeBlocks } from '../../../utils/constants';
import { getDayOfWeek, getStartAndEndFromViewAndDate } from '../../../utils/helpers';
import { EventFilter, EventFormInputs } from 'contexts/EventsContext';
import { FormattedEvent } from 'contexts/EventsContext/utils';
import { FormattedVisitorCode } from 'contexts/VisitorCodesContext/utils';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as FrequencyIcon } from 'assets/icons/frequency.svg';
import { EventInterval } from 'types/Event';
import AGButton from 'components/Button/Button';
import AGForm from 'components/AGForm/AGForm';
import TextInput from 'components/AGForm/Inputs/TextInput/TextInput';
import SingleDateSelector from 'components/SingleDateSelector/SingleDateSelector';
import Label from 'components/AGForm/Inputs/Label/Label';
import AGSimpleSelect, { SelectOption } from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import AGMultipleSelect from 'components/AGSelect/AGMultipleSelect/AGMultipleSelect';
import CarIcon from 'components/Icons/CarIcon';
import TagIcon from 'components/Icons/TagIcon';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { getRecurrenceDropdown, createInitialInputState } from './EventFormHelpers';
import styles from './EventForm.module.css';

type EventFormProps = {
    selectedEvent: FormattedEvent | null;
    visitorCodes: FormattedVisitorCode[];
    selectedSpot: number;
    editEvent: (inputs: EventFormInputs, eventId: number, spot: number, filters: EventFilter) => void;
    addEvent: (inputs: EventFormInputs, spot: number, filters: EventFilter) => void;
    deleteEvent: (eventPk: number, spot: number, filters: EventFilter) => void;
    selectedDate: Date;
    selectedView: View;
    closeEventFormModal: () => void;
};

function EventForm({
    selectedEvent,
    visitorCodes,
    selectedSpot,
    editEvent,
    addEvent,
    deleteEvent,
    selectedDate,
    selectedView,
    closeEventFormModal,
}: EventFormProps) {
    const today = new Date();
    const todayDayOfWeek = getDayOfWeek(today);

    const initialInputState = createInitialInputState(selectedEvent);
    const { handleInput, handleValueChange, resetState, inputs } = useForm(initialInputState);

    const [showNewValidationCode, setShowNewValidationCode] = useState(false);
    const [timeError, setTimeError] = useState<null | string>(null);
    const [recurrenceOptions, setRecurrenceOptions] = useState<SelectOption[]>(
        getRecurrenceDropdown(today, todayDayOfWeek)
    );

    useEffect(() => {
        if (selectedEvent) {
            const newDate = new Date(selectedEvent.date_string);
            const dayOfWeek = getDayOfWeek(newDate);
            setRecurrenceOptions(getRecurrenceDropdown(newDate, dayOfWeek));
        }
    }, [selectedEvent]);

    function validateForm() {
        if (inputs.endTime < inputs.startTime) {
            setTimeError('End time cannot be before start time.');
            return false;
        } else {
            /* reset errors */
            setTimeError(null);
            return true;
        }
    }

    function validateAndSubmit() {
        const isValidated = validateForm();
        const { start, end } = getStartAndEndFromViewAndDate(selectedDate, selectedView);
        if (isValidated) {
            if (inputs.is_lot_closed) inputs.numSpotsAvailable = 0;

            if (selectedEvent) {
                editEvent(inputs, selectedEvent.pk, selectedSpot, { start, end });
            } else {
                addEvent(inputs, selectedSpot, { start, end });
            }
            closeEventFormModal();
            resetState(initialInputState);
        }
    }

    function handleDateChange(date: Date) {
        const dayOfWeek = getDayOfWeek(date);
        handleValueChange('selectedDate', date);
        setRecurrenceOptions(getRecurrenceDropdown(date, dayOfWeek));
    }

    function handleDeleteEvent() {
        const { start, end } = getStartAndEndFromViewAndDate(selectedDate, selectedView);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        deleteEvent(selectedEvent!.pk, selectedSpot, { start, end });
        closeEventFormModal();
    }

    return (
        <div>
            <AGForm>
                <TextInput
                    label="Event Name *"
                    onChange={handleInput}
                    placeholder="Required"
                    errors={null}
                    name="name"
                    value={inputs.name}
                />

                <div>
                    <Label label="Start Date" icon={<CalendarIcon height={20} />} />
                    <SingleDateSelector
                        date={inputs.selectedDate}
                        onSave={(date) => date && handleDateChange(date)}
                        placeHolder="Select Start Date"
                        allowDeSelect={false}
                        noIcon
                        dropdownWidth="320px"
                    />
                </div>

                <div>
                    <Label label="Start and End Time" icon={<CalendarIcon height={20} />} />
                    <div className={styles.timeSelector}>
                        <AGSimpleSelect
                            selected={inputs.startTime}
                            onSelect={(selection) => handleValueChange('startTime', selection)}
                            options={timeBlocks}
                            width="120px"
                            maxHeigh="200px"
                        />
                        <span>to</span>
                        <AGSimpleSelect
                            selected={inputs.endTime}
                            onSelect={(selection) => handleValueChange('endTime', selection)}
                            options={timeBlocks}
                            width="120px"
                            maxHeigh="200px"
                        />
                    </div>
                    {timeError && <ErrorMessage errorMessage={timeError} />}
                </div>

                <div>
                    <Label label="Frequency" icon={<FrequencyIcon height={16} />} />
                    <AGSimpleSelect
                        options={recurrenceOptions}
                        onSelect={(value) => handleValueChange('recurrence', value as EventInterval)}
                        selected={inputs.recurrence}
                        width="100%"
                    />
                </div>

                <button
                    onClick={() => handleValueChange('is_lot_closed', !inputs.is_lot_closed)}
                    type="button"
                    className="button-check"
                >
                    <input type="checkbox" checked={inputs.is_lot_closed} readOnly />
                    <span>Lot is fully closed to the public</span>
                </button>

                {!inputs.is_lot_closed && (
                    <TextInput
                        label="Spots Available to the Public"
                        onChange={handleInput}
                        placeholder="Optional"
                        errors={null}
                        icon={<CarIcon height={16} />}
                        name="numSpotsAvailable"
                        value={inputs.numSpotsAvailable}
                        type="number"
                        min={0}
                    />
                )}

                <div>
                    <Label label="Validation Code" icon={<TagIcon width={18} color="var(--ag-color-gray3)" />} />
                    <AGMultipleSelect
                        selectedValues={inputs.existingVisitorCodePks.map((v) => String(v))}
                        onSelect={(selection) => handleValueChange('existingVisitorCodePks', selection)}
                        options={visitorCodes.map((v) => ({ label: v.text, value: v.value }))}
                        placeholder="Optional"
                        width="320px"
                        maxHeigh="200px"
                        allowNoOptionSelected
                    />
                </div>

                {!showNewValidationCode && (
                    <AGButton
                        type="button"
                        color="info"
                        size="sm"
                        onClick={() => setShowNewValidationCode(!showNewValidationCode)}
                    >
                        Add New Validation Code
                    </AGButton>
                )}

                {showNewValidationCode && (
                    <div>
                        <Label
                            label="New Validation Code"
                            icon={<TagIcon width={18} color="var(--ag-color-gray3)" />}
                        />
                        <div className={styles.newCodeInputs}>
                            <TextInput
                                name="newVisitorCodeName"
                                onChange={handleInput}
                                label=""
                                placeholder="Name"
                                value={inputs.newVisitorCodeName}
                                errors={null}
                                width="148px"
                            />
                            <TextInput
                                name="newVisitorCodeCode"
                                onChange={handleInput}
                                label=""
                                placeholder="Code"
                                value={inputs.newVisitorCodeCode}
                                errors={null}
                                width="148px"
                            />
                        </div>
                    </div>
                )}

                <div className={styles.buttonGroup}>
                    {selectedEvent && (
                        <AGButton type="button" color="error-outline" onClick={handleDeleteEvent}>
                            Delete
                        </AGButton>
                    )}
                    <AGButton
                        type="button"
                        color="primary"
                        disabled={!inputs.name || !inputs.selectedDate}
                        onClick={validateAndSubmit}
                    >
                        {selectedEvent ? 'Save Event' : 'Add Event'}
                    </AGButton>
                </div>
            </AGForm>
        </div>
    );
}

export default EventForm;
