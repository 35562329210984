import moment from 'moment';
import 'moment-timezone';
import { RentType, QUICKPAY_BASE_URL, rentalTypesForReports } from './constants';
import { MonthYear } from './monthYear';
import { View } from 'react-big-calendar';

export const getObjectIfExists = (object: any) => {
    return object && Object.keys(object).length > 0 ? object : null;
};

export const getDayOfWeek = (date: Date) => {
    return moment(date).format('dddd');
};

export const getOrdinalDayOfWeekInMonth = (date: Date) => {
    const dayOfMonth = date.getDate();
    const ordinalDayOfWeekInMonth = Math.floor(dayOfMonth / 7) + 1;
    return ordinalDayOfWeekInMonth;
};

export const getDropdownNumberOptions = (max: number) => {
    const optionsArray: Array<object> = [];
    for (let i = 0; i < max; i++) {
        optionsArray.push({ key: i, text: `${i}`, value: i });
    }
    return optionsArray;
};

export const getDropdownOptionsFromArray = (array: number[]) => {
    return array.map((n) => ({ key: n, text: `${n}`, value: n }));
};

export const getOrdinalDayOfWeekInMonthWord = (date: Date) => {
    const ordinalNumber = getOrdinalDayOfWeekInMonth(date);
    interface OrdinalWordsInterface {
        1: string;
        2: string;
        3: string;
        4: string;
        [key: string]: string;
    }
    const ordinalWords: OrdinalWordsInterface = {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fifth',
    };
    return ordinalWords[ordinalNumber.toString()];
};

export const getEnforcementURL = (status: string, currentlyParkedViolators: boolean) => {
    let filteredUrl = '';
    if (currentlyParkedViolators) {
        filteredUrl += '&currently_parked=true&current_violators=true';
    }
    filteredUrl += '&status=';
    if (status === 'Valid rental' || status === 'Auto started' || status === 'Not found') {
        filteredUrl += status;
    } else if (status === 'Enforcer scans') {
        filteredUrl += 'Not found&source__in=AirGarage App,Spaceforce App';
    } else if (status === 'Camera scans') {
        filteredUrl += 'Not found&owner__spaceforce_username=flock';
    } else if (status === 'Notes left') {
        filteredUrl += 'Not found&action=note';
    }
    return filteredUrl;
};

export const isPhone = (phone: string): boolean => /^\d+$/.test(phone);

export const validateEmail = (email: string): boolean => {
    const ok = /\S+@\S+\.\S+/.test(email);
    const noEmptySpaces = /^\S*$/.test(email);
    return ok && noEmptySpaces;
};

export const dateToUnix = (date: Date) => {
    return Math.floor(date.getTime() / 1000);
};

export const resetCalendarRange = () => {
    const start = dateToUnix(moment(new Date()).startOf('week').toDate());
    const end = dateToUnix(moment(new Date()).endOf('week').toDate());
    return { start, end };
};

export function getStartAndEndFromViewAndDate(date: Date, view: View): { start: number; end: number } {
    let start = moment(),
        end = moment();

    switch (view) {
        case 'day':
            start = moment(date).startOf('day');
            end = moment(date).endOf('day').add(1, 'days');
            break;
        case 'week':
            start = moment(date).startOf('week');
            end = moment(date).endOf('week');
            break;
        case 'month':
            start = moment(date).startOf('month').subtract(7, 'days'); //extra 7 days to fill month grid
            end = moment(date).endOf('month').add(7, 'days');
            break;
        case 'agenda':
            start = moment(date).startOf('day');
            end = moment(date).endOf('day').add(1, 'month');
            break;
    }

    return { start: dateToUnix(start.toDate()), end: dateToUnix(end.toDate()) };
}

export function isActiveTab(t: string): boolean {
    return window.location.pathname.toLowerCase().includes(t);
}

export const getFirstDayOfMonth = (date: Date): Date => {
    return moment(date).startOf('month').toDate();
};

export const baseUrl = (rentalType: RentType, spotPK: number, spotRef?: string): string => {
    if (spotRef) {
        return `${QUICKPAY_BASE_URL}spots/${spotPK}/?rental_type=${rentalType}&ref=${spotRef}`;
    } else {
        return `${QUICKPAY_BASE_URL}spots/${spotPK}/?rental_type=${rentalType}`;
    }
};

type DownloadFileProps = {
    data: BlobPart;
    fileName: string;
    fileType: string;
};

export const downloadFile = ({ data, fileName, fileType }: DownloadFileProps) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export function isValidRentalTypeForReports(x: unknown): x is keyof typeof rentalTypesForReports {
    return typeof x === 'string' && Object.keys(rentalTypesForReports).includes(x);
}

export function isValidDateForReports(x: unknown): x is MonthYear['value'] {
    if (typeof x !== 'string') return false;
    const [month, year] = x.split(':');
    const yearNumber = parseInt(year);
    const isValidYear = !(isNaN(yearNumber) || yearNumber < 2019 || yearNumber > new Date().getFullYear());
    if (!isValidYear) return false;
    const monthNumber = parseInt(month);
    const isValidMonth = !(isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12);
    if (!isValidMonth) return false;
    const today = new Date();
    return yearNumber !== today.getFullYear() || monthNumber <= today.getMonth() + 1;
}

export function isValidDateForIncomeStatement(x: unknown): x is string {
    if (typeof x !== 'string') return false;
    const [year, month, day] = x.split('-');

    const yearNumber = parseInt(year);
    const monthNumber = parseInt(month);
    const dayNumber = parseInt(day);

    const today = new Date();
    const date = newUTCDate(x);
    return (
        date.getMonth() + 1 === monthNumber &&
        date.getFullYear() === yearNumber &&
        date.getDate() === dayNumber &&
        date <= today
    );
}

export function isValidDateForCarTrackingTable(stringDate: string): boolean {
    const { month, day, year } = getYearMonthDayFromString(stringDate);
    const date = new Date(year, month, day);
    return date instanceof Date && !isNaN(date as unknown as number);
}

export function removeDecimalFromString(price: string) {
    return price.replace(/[.][0-9]+$/, '');
}

export function usdFormatterWithoutCents(price: number) {
    const usd = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
    });
    return usd.format(price);
}

export function newUTCDate(date: string) {
    return new Date(date + 'T00:00');
}

export function getFormattedDate(date: Date) {
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);

    return formattedDate.replaceAll('-', '/');
}

export function getFormattedDateYYYY_MM_DD(date: Date) {
    const timeZoneCorrectedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return timeZoneCorrectedDate.toISOString().split('T')[0];
}

export function getFormattedDateAndTime(date: Date) {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date);
}

export function formatToISOWithoutMillisecondsAndTimezone(date: string) {
    return date.replaceAll('T', ' ').replaceAll('Z', '').split('.')[0];
}

export function dateFormatter(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
    });
}

export function formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
}

export function formatDateRange(date1: Date, date2: Date): string {
    const y1 = date1.getFullYear();
    const y2 = date2.getFullYear();

    if (y1 === y2) {
        const m1 = date1.getMonth();
        const m2 = date2.getMonth();

        if (m1 === m2) {
            return `${formatDate(date1)} - ${date2.getDate()}, ${y1}`;
        }
        return `${formatDate(date1)} - ${formatDate(date2)}, ${y1}`;
    }
    return `${formatDate(date1)}, ${y1} - ${formatDate(date2)}, ${y2}`;
}

export function timeFormatter(timestamp: number) {
    return new Date(timestamp * 1000)
        .toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
        .split(',')[1]
        .trim();
}

export function dayNumberForPython(date: Date) {
    /* In javascript Sunday = 0, whereas in python Monday = 0 🤨*/
    const javascriptDay = date.getDay();
    return javascriptDay === 0 ? 6 : javascriptDay - 1;
}

export function getTimezoneOffset() {
    const date = new Date(),
        timezoneOffset = date.getTimezoneOffset(),
        hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2),
        minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2);
    return (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
}

export function dateWithTimeZone(
    timeZone: string,
    year: number,
    month: number,
    day: number,
    hour: number = 0,
    minute: number = 0,
    second: number = 0
): Date {
    const date = new Date(Date.UTC(year, month, day, hour, minute, second));
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);

    return date;
}

export function getYearMonthDayFromString(date: string) {
    const [month, day, year] = date.split('/').map((value) => Number(value));
    return { month, day, year };
}

type GenericFunction = (...args: any[]) => void;
interface DebouncedFunction<T extends GenericFunction> {
    (...args: Parameters<T>): void;
    cancel: () => void;
}

export function debounce<T extends GenericFunction>(func: T, delay: number): DebouncedFunction<T> {
    let timeoutId: ReturnType<typeof setTimeout>;

    function debouncedFunction(this: ThisParameterType<T>, ...args: Parameters<T>): void {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    }

    function cancel() {
        clearTimeout(timeoutId);
    }

    return Object.assign(debouncedFunction as T, { cancel });
}

export function parseQueryParams(search: string) {
    const params = new URLSearchParams(search);
    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
        queryParams[key] = value;
    });
    return queryParams;
}

export function dateTimeWithTimezone(date: Date, timeZone: string): string {
    return date.toLocaleDateString('en-US', {
        timeZone,
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });
}
