import React from 'react';
import VisitorForm, { VisitorFormState } from './VisitorForm/VisitorForm';
import useModal from './../../hooks/useModal';
import { Visitor } from 'types/Visitor';
import { getStateFromValue } from 'assets/data/countriesAndStates';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { getFormattedDateAndTime } from 'utils/helpers';
import IconButton from 'components/Button/IconButton';
import { FormattedVisitorCode } from 'contexts/VisitorCodesContext/utils';

type VisitorCardAndFormProps = {
    deleteVisitor: (visitorPk: number) => void;
    editVisitor: (updatedVisitor: VisitorFormState, visitorPk: number) => void;
    visitor: Visitor;
    visitorCodes: FormattedVisitorCode[];
};

function VisitorCardAndForm(props: VisitorCardAndFormProps) {
    const { isShowing, toggle } = useModal();
    const { visitor, visitorCodes, editVisitor, deleteVisitor } = props;
    const car = visitor.car_details || null;
    const state = getStateFromValue(car?.state, car?.country);

    const validationSource = visitor.validation_source.replace('-', ' ');
    const validUntil = visitor.visitor_sessions[0] ? visitor.visitor_sessions[0].end_datetime : visitor.valid_until;
    const validUntilDate = new Date(Number(validUntil) * 1000);

    return (
        <tr key={visitor.pk}>
            {isShowing && (
                <VisitorForm
                    visitor={visitor}
                    editVisitor={editVisitor}
                    deleteVisitor={deleteVisitor}
                    toggle={toggle}
                    visitorCodes={visitorCodes}
                />
            )}

            <td>
                {car.plate}
                {state && ` - ${state.value}`}
            </td>
            <td>{visitor.name}</td>
            <td>{visitor.validity_reason}</td>
            <td style={{ textTransform: 'capitalize' }}>{validationSource}</td>
            <td>{visitor.is_always_valid ? 'Always Valid' : getFormattedDateAndTime(validUntilDate)}</td>
            <td>
                <IconButton onClick={toggle}>
                    <EditIcon data-testid={`edit-${visitor.pk}`} fill="#808080" />
                </IconButton>
            </td>
        </tr>
    );
}

export default VisitorCardAndForm;
