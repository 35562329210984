import { CountryCode } from 'assets/data/countriesAndStates';
import React from 'react';
import { Button, Form, Modal, Header, Divider, Icon, Label } from 'semantic-ui-react';
import useForm from 'hooks/useForm';
import { Visitor } from 'types/Visitor';
import StateField from '../StateField/StateField';
import { FormattedVisitorCode } from 'contexts/VisitorCodesContext/utils';

export interface VisitorFormState {
    name: string;
    plate: string;
    phone: string;
    code: string;
    state: string;
    country: CountryCode;
}

type BaseProps = {
    toggle(): void;
    visitorCodes: FormattedVisitorCode[];
};

type EditProps = BaseProps & {
    visitor: Visitor;
    deleteVisitor: (visitorPk: number) => void;
    editVisitor: (updatedVisitor: VisitorFormState, visitorPk: number) => void;
};

type AddNewProps = BaseProps & {
    visitor: null;
    addVisitor: (visitor: VisitorFormState) => void;
};

type VisitorFormProps = AddNewProps | EditProps;

const VisitorForm = (props: VisitorFormProps) => {
    const { visitor, visitorCodes, toggle } = props;
    let code = '';
    if (visitor && visitor.codes && visitor.codes.length) {
        code = visitor.codes[0] ? visitor.codes[0].toString() : '';
    }
    const initialInputState: VisitorFormState = {
        name: visitor ? visitor.name : '',
        plate: visitor ? visitor.car_details?.plate : '',
        phone: visitor && visitor.phone ? visitor.phone : '',
        code: code,
        state: visitor ? visitor.car_details?.state : '',
        country: visitor ? visitor.car_details?.country : 'US',
    };

    const { handleInput, handleDropdown, handleValueChange, inputs } = useForm(initialInputState);

    const handleSubmit = () => {
        if ((props as any).editVisitor) {
            const { editVisitor } = props as EditProps;
            editVisitor({ ...inputs }, (visitor as Visitor).pk);
        } else if ((props as any).addVisitor) {
            (props as AddNewProps).addVisitor({ ...inputs });
        }
        toggle();
    };

    const emptyCarFields = !inputs.plate || !inputs.state;
    const emptyCode = !inputs.code;
    const disableButton = emptyCode || emptyCarFields;

    return (
        <Modal as={Form} open onClose={toggle}>
            <Header content={visitor && visitor.pk ? 'Edit visitor validation' : 'Validate new visitor'} as="h2" />
            <Modal.Content>
                {(visitor?.created_at || visitor?.updated_at) && (
                    <div>
                        <Icon circular name="calendar alternate outline" />
                        {visitor.created_at && (
                            <Label>
                                Created:
                                <Label.Detail>{visitor.created_at}</Label.Detail>
                            </Label>
                        )}
                        {visitor.updated_at && (
                            <Label>
                                Last Updated:
                                <Label.Detail>{visitor.updated_at}</Label.Detail>
                            </Label>
                        )}
                        <Divider hidden></Divider>
                    </div>
                )}
                <Form.Field>
                    <Form.Dropdown
                        required
                        autoFocus
                        selection
                        name="code"
                        placeholder="Validation Code *"
                        options={visitorCodes}
                        onChange={handleDropdown}
                        value={inputs.code}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        name="name"
                        placeholder="Visitor name and notes (50 characters max)"
                        onChange={handleInput}
                        value={inputs.name}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        name="phone"
                        placeholder="Phone"
                        type="tel"
                        onChange={handleInput}
                        value={inputs.phone}
                    />
                </Form.Field>

                <Form.Field>
                    <Header as="h5">Car:</Header>
                    <Form.Input
                        required
                        autoCapitalize={'characters'}
                        name="plate"
                        placeholder="Plate *"
                        onChange={handleInput}
                        value={inputs.plate}
                    />
                </Form.Field>
                <StateField handleValueChange={handleValueChange} state={inputs.state} country={inputs.country} />
            </Modal.Content>
            <Modal.Actions>
                {visitor && visitor.pk && (props as any).deleteVisitor && (
                    <Button
                        floated="left"
                        basic
                        color="red"
                        type="button"
                        content="Delete"
                        onClick={() => {
                            props.toggle();
                            (props as EditProps).deleteVisitor(visitor.pk);
                        }}
                    />
                )}
                <Button
                    disabled={disableButton}
                    type="submit"
                    floated="right"
                    color="green"
                    icon="save"
                    content="Save"
                    onClick={handleSubmit}
                />
                <Divider hidden />
                <Divider hidden />
            </Modal.Actions>
        </Modal>
    );
};

export default VisitorForm;
